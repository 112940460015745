@media (max-width: 767px) {
  .sticky-header {
    top: 800px; /* Set top to 150px for screens with maximum width of 767px (commonly used for mobile devices) */
  }
}

/* customScrollbar.css */

/* width */
.table-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.table-scroll::-webkit-scrollbar-track {
  box-shadow: inset 2 0 2px grey;
  border-radius: 5px;
}

/* Handle */
.table-scroll::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
  border-radius: 10px;
}

/* Handle on hover */
.table-scroll::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
}
